




import Vue from 'vue';

export default Vue.extend({
  name: 'AppLoader',
  inheritAttrs: false,
});
