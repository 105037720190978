



























import Vue from 'vue';
import AppLoader from '@/components/AppLoader.vue';

export default Vue.extend({
  name: 'AppMain',
  components: {
    AppLoader,
  },
  inheritAttrs: false,
  props: {
    loading: Boolean,
    ready: {
      type: Boolean,
      default: true,
    },
    flush: Boolean,
  },
  computed: {
    showLoader(): boolean {
      return this.loading && !this.ready;
    },
  },
});
